/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../app/store"
import { userType } from "./usersSlice"

export type Gatekeeper = {
  id: string
  country_code?: string
  mobile: string
  email: string
  first_name: string
  last_name: string
}

export type centerType = {
  id: string
  uuid: string
  name: string
  address: string
  google_maps_url: string
  city: string
  amenities: string[]
  working_hours_start: string
  working_hours_end: string
  is_day_pass_enabled: boolean
  day_pass_price: number
  images: string[]
  description: string
  rules: string
  gatekeeper_ids: string[]
  gatekeeper_details: Gatekeeper[]
  google_maps_thumbnail_url: string | null
  is_active: boolean
  cluster_name: string
}

type CentersState = {
  centers: centerType[]
  centerDetails: centerType
  isCenterDetailsLoading: boolean
  users: userType[]
  isCenterLoading: boolean
  isCenterDataSaved: boolean
  centerClusterDetails: centerType[]
  isCenterClusterDetailsLoading: boolean
}

const initialCenterDetailsState = {
  id: "",
  uuid: "",
  name: "",
  address: "",
  google_maps_url: "",
  city: "",
  amenities: [],
  working_hours_start: "",
  working_hours_end: "",
  is_day_pass_enabled: false,
  day_pass_price: 0,
  images: [],
  description: "",
  rules: "",
  gatekeeper_ids: [],
  gatekeeper_details: [],
  google_maps_thumbnail_url: null,
  is_active: false,
  cluster_name: "",
}

export const centersInitialState: CentersState = {
  centers: [],
  users: [],
  centerDetails: initialCenterDetailsState,
  centerClusterDetails: [],
  isCenterClusterDetailsLoading: false,
  isCenterDetailsLoading: false,
  isCenterLoading: false,
  isCenterDataSaved: false,
}

export type addCenterPayloadType = {
  payload: {
    centerName: string
    googleMapsLocation: string
    dayPassAvailable: boolean
    dayPassPrice: number
    openTime: string
    closeTime: string
    amenities: string[]
    files: File[]
    description: string
    rules: string
    gatekeeperIds: string[]
    clusterName: string
  }
}

export type updateCenterPayloadType = {
  payload: {
    centerId: string
    centerName: string
    areaName: string
    googleMapsLocation: string
    dayPassAvailable: boolean
    dayPassPrice: number
    openTime: string
    closeTime: string
    amenities: string[]
    description: string
    rules: string
    images: string[]
    newImages: File[]
    gatekeeperIds: string[]
    clusterName: string
  }
}

export type centerDetailsPayloadType = {
  payload: {
    centerId: string
  }
}

export type fetchUsersByNamePayloadType = {
  payload: {
    name: string
  }
}

export const centersSlice = createSlice({
  name: "centers",
  initialState: centersInitialState,
  reducers: {
    fetchCenters: (state) => {
      state.centers = []
      state.isCenterLoading = true
    },
    fetchCentersSuccess: (state, { payload }: { payload: { centers: [] } }) => {
      state.centers = payload.centers
      state.isCenterLoading = false
    },
    fetchCentersFailed: (state) => {
      state.isCenterLoading = false
    },

    addCenter: (state, action: addCenterPayloadType) => {
      state.isCenterLoading = true
    },
    addCenterSuccess: (
      state,
      { payload }: { payload: { centerDeatils: centerType } },
    ) => {
      state.isCenterLoading = false
      state.centerDetails = payload.centerDeatils
      state.isCenterDataSaved = true
    },
    addCenterFailed: (state) => {
      state.isCenterLoading = false
    },

    updateCenter: (state, action: updateCenterPayloadType) => {
      state.isCenterLoading = true
    },
    updateCenterSuccess: (
      state,
      { payload }: { payload: { centerDeatils: centerType } },
    ) => {
      state.isCenterLoading = false
      state.centerDetails = payload.centerDeatils
      state.isCenterDataSaved = true
    },
    updateCenterFailed: (state) => {
      state.isCenterLoading = false
    },

    fetchUsers: (state) => {
      // Empty function body
    },
    fetchUsersSuccess: (state, { payload }: { payload: { users: [] } }) => {
      state.users = payload.users
    },
    fetchUsersFailed: (state) => {
      // Empty function body
    },

    fetchUsersByName: (state, action: fetchUsersByNamePayloadType) => {
      // Empty function body
    },
    fetchUsersByNameSuccess: (
      state,
      { payload }: { payload: { users: [] } },
    ) => {
      state.users = payload.users
    },
    fetchUsersByNameFailed: (state) => {
      // Empty function body
    },

    fetchCenterDetails: (state, action: centerDetailsPayloadType) => {
      state.centerDetails = initialCenterDetailsState
      state.isCenterDetailsLoading = true
    },
    fetchCenterDetailsSuccess: (
      state,
      { payload }: { payload: { centerDeatils: centerType } },
    ) => {
      state.centerDetails = payload.centerDeatils
      state.isCenterDetailsLoading = false
    },
    fetchCenterDetailsFailed: (state) => {
      state.isCenterDetailsLoading = false
    },

    fetchCenterClusterDetails: (state) => {
      state.centerClusterDetails = centersInitialState?.centerClusterDetails
      state.isCenterClusterDetailsLoading = true
    },

    fetchCenterClusterDetailsSuccess: (
      state,
      { payload }: { payload: { centerClusterDetails: any } },
    ) => {
      state.centerClusterDetails = payload.centerClusterDetails
      state.isCenterDetailsLoading = false
    },
    fetchCenterClusterDetailsFailed: (state) => {
      state.isCenterDetailsLoading = false
    },

    resetData: (state) => {
      state.centerDetails = initialCenterDetailsState
      state.isCenterDataSaved = false
    },
  },
})

export const {
  fetchCenters,
  fetchCentersFailed,
  fetchCentersSuccess,
  addCenter,
  addCenterSuccess,
  addCenterFailed,
  updateCenter,
  updateCenterSuccess,
  updateCenterFailed,
  fetchUsers,
  fetchUsersFailed,
  fetchUsersSuccess,
  fetchUsersByName,
  fetchUsersByNameSuccess,
  fetchUsersByNameFailed,
  fetchCenterDetails,
  fetchCenterDetailsFailed,
  fetchCenterDetailsSuccess,
  fetchCenterClusterDetails,
  fetchCenterClusterDetailsFailed,
  fetchCenterClusterDetailsSuccess,
  resetData,
} = centersSlice.actions

export const selectCenters = (state: RootState) => state.centers

export default centersSlice.reducer
