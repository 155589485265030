import axiosConfig from "../app/axiosConfig"
import {
  CUSTOMER_PENDING_INVOICES,
  CUSTOMER_SEARCH_FOR_RECONCILIATION,
  INITIATE_PROCESS_SCHEDULE_API,
  INVOICE_DETAILS_API,
  INVOICE_ENQUEUE,
  PROCESS_SCHEDULE_API,
  TRANSACTION_BANKS_API,
  TRANSACTION_DOWNLOAD_API,
  TRANSACTION_LIST_API,
  TRANSACTION_PAYMENT_METHODS_API,
  TRANSACTION_UPLOAD_API,
} from "../constants/apis"
import { ApiResponse } from "../models/api-response.model"
import { CustomerSearchResponse } from "../models/customer.model"
import { CustomerPendingInvoicesResponse } from "../models/invoice.model"
import { ReconciliationDTO } from "../models/reconciliation.model"
import {
  getTransactionListPayloadType,
  uploadCSVFilePayloadType,
} from "../slices/transactionsSlice"
import { computeRoute } from "../utils/compute-route"
import { filterService } from "../utils/transactionHelpers"

export const fetchTransactionListService = ({
  payload,
}: getTransactionListPayloadType) => {
  const parameters = filterService({ payload })
  return axiosConfig.get(TRANSACTION_LIST_API, { params: parameters })
}

export const uploadCSVFileService = ({ payload }: uploadCSVFilePayloadType) => {
  const formData = new FormData()
  formData.append(`file`, payload.file)
  formData.append(`bank_account_id`, payload.accountId)
  return axiosConfig.post(TRANSACTION_UPLOAD_API, formData)
}

export const downloadCSVFileService = ({
  payload,
}: getTransactionListPayloadType) => {
  const parameters = filterService({ payload })
  return axiosConfig.get(TRANSACTION_DOWNLOAD_API, {
    responseType: "blob",
    params: parameters,
  })
}

export const getBankListService = () => axiosConfig.get(TRANSACTION_BANKS_API)

export const getPaymentMethodsService = () =>
  axiosConfig.get(TRANSACTION_PAYMENT_METHODS_API)

export const getInvoiceDetailsService = ({
  payload,
}: {
  payload: { invoice_number: string }
}) => {
  return axiosConfig.get(INVOICE_DETAILS_API, {
    params: payload,
  })
}

export const fetchProcessScheduleData = () => {
  return axiosConfig.get(PROCESS_SCHEDULE_API)
}

export const intiateProcessSchedule = () => {
  return axiosConfig.post(INITIATE_PROCESS_SCHEDULE_API)
}

// Reconciliation
export const searchCustomers = async ({
  searchTerm,
  transactionSource,
}: {
  searchTerm: string
  transactionSource: string
}): Promise<ApiResponse<CustomerSearchResponse>> => {
  const params = { search: searchTerm, source: transactionSource }
  const { data } = await axiosConfig.get<ApiResponse<CustomerSearchResponse>>(
    CUSTOMER_SEARCH_FOR_RECONCILIATION,
    {
      params,
    },
  )
  return data
}

export const fetchCustomerPendingInvoices = async (
  customerId: string,
): Promise<ApiResponse<CustomerPendingInvoicesResponse>> => {
  const { data } = await axiosConfig.get<
    ApiResponse<CustomerPendingInvoicesResponse>
  >(
    computeRoute(CUSTOMER_PENDING_INVOICES, {
      customerId,
    }),
  )
  return data
}

export const submitReconciliation = async (
  reconciliationData: ReconciliationDTO,
): Promise<ApiResponse<Record<string, never>>> => {
  const { data } = await axiosConfig.post(INVOICE_ENQUEUE, reconciliationData)
  return data
}
