import React, { useEffect, useState } from "react"
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Stack,
  Box,
  Autocomplete,
  Select,
  Skeleton,
  MenuItem,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from "@mui/material"
import dayjs, { Dayjs } from "dayjs"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo"
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import {
  addCenter,
  selectCenters,
  updateCenter,
  fetchUsersByName,
  fetchCenterDetails,
  resetData,
  Gatekeeper,
  fetchCenterClusterDetails,
} from "../../slices/centersSlice"

import { Navigate, useNavigate, useParams } from "react-router-dom"
import PageHeader from "../../components/PageHeader/PageHeader"
import ElevatedCard from "../../components/ElevatedCard/ElevatedCard"
import FileUploadCarousel from "../../components/FileUploadCrousel/FileUploadCrousel"
import { CENTERS } from "../../constants/path"
import CommonAutocomplete from "../../components/CommonAutocomplete/CommonAutocomplete"

const Amenities = [
  "Library",
  "Pet-friendly",
  "Conferencing facilities",
  "Outdoor space",
  "Parking",
  "Lounge",
]

interface FormValues {
  centerName: string
  areaName: string
  googleMapsLocation: string
  dayPassAvailable: boolean
  dayPassPrice: number
  description: string
  rules: string
  gatekeeperIds: Gatekeeper[]
  clusterName: string
}

const AddCenterForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { centerId } = useParams()
  const {
    centerDetails,
    centerClusterDetails,
    isCenterDetailsLoading,
    isCenterDataSaved,
    users,
  } = useAppSelector(selectCenters)

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  // form states
  const [openTime, setOpenTime] = useState<Dayjs | null>(
    dayjs(`2022-04-17T9:00`),
  )
  const [closeTime, setCloseTime] = useState<Dayjs | null>(
    dayjs(`2022-04-17T17:00`),
  )
  const [amenities, setAmenities] = useState<string[]>(
    centerDetails?.amenities || [],
  )
  const [formValues, setFormValues] = useState<FormValues>({
    centerName: "",
    areaName: "",
    googleMapsLocation: "",
    dayPassAvailable: false,
    dayPassPrice: 0,
    description: "",
    rules: "",
    gatekeeperIds: [],
    clusterName: "",
  })

  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [remoteImages, setRemoteImages] = useState<string[]>([])

  // Call the cluster api for displaying cluster dropdown
  useEffect(() => {
    dispatch(fetchCenterClusterDetails())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (centerId !== "add")
      dispatch(fetchCenterDetails({ centerId: centerId || "" }))

    return () => {
      dispatch(resetData())
    }
  }, [dispatch, centerId])

  useEffect(() => {
    if (centerDetails.name !== "") {
      setOpenTime(dayjs("2022-04-17T" + centerDetails?.working_hours_start))
      setCloseTime(dayjs("2022-04-17T" + centerDetails?.working_hours_end))
      const validAmenities = centerDetails?.amenities
        ? centerDetails?.amenities.filter((amenity) => amenity !== null)
        : []
      setAmenities(validAmenities)
      setFormValues({
        centerName: centerDetails?.name,
        clusterName: centerDetails?.cluster_name,
        areaName: centerDetails?.address,
        googleMapsLocation: centerDetails?.google_maps_url,
        dayPassAvailable: centerDetails?.is_day_pass_enabled,
        dayPassPrice: centerDetails?.day_pass_price,
        description: centerDetails?.description || "",
        rules: centerDetails?.rules || "",
        gatekeeperIds:
          (centerDetails?.gatekeeper_details &&
            centerDetails.gatekeeper_details) ||
          [],
      })
      setRemoteImages(centerDetails?.images)
    }
  }, [centerDetails])

  // input change handler
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }))
  }

  const handleClusterDropdownSelect = (e: SelectChangeEvent<string>) => {
    const { value } = e.target
    setFormValues((prevValues) => ({
      ...prevValues,
      clusterName: value,
    }))
  }

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    centerId !== "add"
      ? dispatch(
          updateCenter({
            ...formValues,
            gatekeeperIds: formValues.gatekeeperIds.map(
              (gatekeeper) => gatekeeper.id,
            ),
            amenities,
            openTime: dayjs(openTime).format("HH:mm"),
            closeTime: dayjs(closeTime).format("HH:mm"),
            centerId: centerId || "",
            images: remoteImages,
            newImages: selectedFiles,
          }),
        )
      : dispatch(
          addCenter({
            ...formValues,
            gatekeeperIds: formValues.gatekeeperIds.map(
              (gatekeeper) => gatekeeper.id,
            ),
            amenities,
            openTime: dayjs(openTime).format("HH:mm"),
            closeTime: dayjs(closeTime).format("HH:mm"),
            files: selectedFiles,
          }),
        )
  }

  return (
    <Box>
      {isCenterDataSaved && (
        <Navigate to={`${CENTERS}/details/${centerDetails.id}`} />
      )}
      <PageHeader
        title={centerId !== "add" ? "Update Center" : "Add Center"}
        breadcrums={[
          { title: "Centers", path: CENTERS },
          { title: centerId !== "add" ? "Add" : "Update" },
        ]}
      />
      <ElevatedCard style={{ width: isSmallScreen ? "84%" : "508px" }}>
        {isCenterDetailsLoading ? (
          <Stack gap={2}>
            <Skeleton variant="rectangular" height={100} />
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" height={50} />
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" height={50} />
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" />
            <Skeleton variant="rectangular" height={50} />
            <Skeleton variant="rectangular" />
          </Stack>
        ) : (
          <Stack component="form" gap={2} onSubmit={handleSubmit}>
            <Typography variant="h6">
              {centerId !== "add"
                ? "Update Center Details"
                : "Add Center Details"}
            </Typography>
            <TextField
              name="centerName"
              label="Center Name"
              value={formValues.centerName}
              onChange={handleInputChange}
              required
              size="small"
            />
            {centerDetails?.address && (
              <TextField
                name="areaName"
                label="Address"
                value={formValues.areaName}
                onChange={handleInputChange}
                required
                size="small"
              />
            )}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["MobileTimePicker"]}>
                <Stack direction="row" gap={5} justifyContent="space-betweeen">
                  <DemoItem label="Open Time">
                    <MobileTimePicker
                      value={openTime}
                      onChange={(newValue) => setOpenTime(newValue)}
                    />
                  </DemoItem>
                  <DemoItem label="Close Time">
                    <MobileTimePicker
                      value={closeTime}
                      onChange={(newValue) => setCloseTime(newValue)}
                    />
                  </DemoItem>
                </Stack>
              </DemoContainer>
            </LocalizationProvider>
            <Autocomplete
              id="select-amenities"
              options={Amenities}
              value={amenities}
              onChange={(_, newValue) => {
                setAmenities(newValue || [])
              }}
              multiple
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Amenities"
                  variant="outlined"
                  size="small"
                />
              )}
            />
            <CommonAutocomplete
              options={users}
              getOptionLabel={(option) =>
                option
                  ? `${option.mobile} (${option.first_name} ${option.last_name})`
                  : ``
              }
              value={formValues.gatekeeperIds}
              onChange={(newValue) => {
                setFormValues({
                  ...formValues,
                  gatekeeperIds: Array.isArray(newValue)
                    ? newValue
                    : newValue
                    ? [newValue]
                    : [],
                })
              }}
              placeholder="type a name to see option"
              label="Gatekeeper"
              multiple
              fetchOptionsDebounced={(inputValue) => {
                dispatch(fetchUsersByName({ name: inputValue }))
              }}
            />
            <TextField
              name="googleMapsLocation"
              label="Google Maps URL"
              value={formValues.googleMapsLocation}
              onChange={handleInputChange}
              required
              size="small"
            />
            {/* Cluster Dropdown */}
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Cluster</InputLabel>
                <Select
                  labelId="cluster-select-label"
                  id="cluster-select"
                  label="Cluster"
                  name="cluster"
                  value={formValues.clusterName || ""}
                  onChange={(e) => handleClusterDropdownSelect(e)}
                >
                  {centerClusterDetails.map((cluster) => (
                    <MenuItem key={cluster.id} value={cluster.name}>
                      {cluster.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Stack
              direction={isSmallScreen ? "column" : "row"}
              gap={isSmallScreen ? 2 : 10}
              justifyContent="space-betweeen"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formValues.dayPassAvailable}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        dayPassAvailable: e.target.checked,
                      })
                    }
                  />
                }
                label="Day pass availability"
              />
              {formValues.dayPassAvailable && (
                <TextField
                  type="number"
                  name="dayPassPrice"
                  label="Day pass price per day"
                  value={formValues.dayPassPrice}
                  onChange={handleInputChange}
                  required
                  size="small"
                  inputProps={{
                    min: "1",
                  }}
                />
              )}
            </Stack>
            <TextField
              multiline
              rows={2}
              name="description"
              label="Description"
              value={formValues.description}
              onChange={handleInputChange}
              size="small"
            />
            <TextField
              multiline
              rows={2}
              name="rules"
              label="Rules"
              value={formValues.rules}
              onChange={handleInputChange}
              size="small"
            />
            <FileUploadCarousel
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              remoteImages={remoteImages}
              setRemoteImages={setRemoteImages}
            />
            <Stack direction="row" gap={10}>
              <Button
                type="submit"
                sx={{ flexGrow: 1 }}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
              <Button
                sx={{ flexGrow: 1 }}
                onClick={() => navigate(-1)}
                variant="contained"
                color="secondary"
              >
                Back
              </Button>
            </Stack>
          </Stack>
        )}
      </ElevatedCard>
    </Box>
  )
}

export default AddCenterForm
