import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../app/store"
import dayjs, { Dayjs } from "dayjs"
import { DaypassType, PaymentStatus } from "../constants/data"

export type bookingListType = {
  id: string
  amount: number
  daypass_type: string
  booking_code: string
  booking_date: string
  booking_ref: string
  invoice_url: string
  created_by: string | null
  center_id: string
  payment_status: string
  payment_order_id: string
  is_active: boolean
  user_id: string | null
  user_details: {
    id: string
    first_name: string | null
    last_name: string | null
    mobile: string
    profile_image: string | null
    country_code: string
    created_at: string
    status: string
    email: string | null
  }
  center_details: {
    id: string
    name: string
    working_hours_start: string
    address: string
    images: string[]
    google_maps_url: string
    city: string
    contact_person_name: string
    contact_person_phone: string
    day_pass_price: number
  }
  meeting_room_details: {
    name: string
  }
  day_passes_data: {
    created_at: string
    id: string
    total_pass_count: number
    used_pass_count: number
    unused_pass_count: number
    pass_status: string
    start_date: string
    end_date: string
  }
}

type bookingDetailsType = {
  profile_image: string
  first_name: string
  last_name: string
  email: string
  mobile: string
  kyc_image: string
  status: string
  id: string
  roles: any
}

type FilterType = {
  dateSelector: string
  startDate: Dayjs | null
  endDate: Dayjs | null
  center?: string | null
  type?: string[]
  isActive?: boolean | null
  paymentStatus?: string[]
  bookingRef?: string | null
  bookingCode?: string
}

type BookingHistoryState = {
  bookingList: bookingListType[]
  isLoading: boolean
  bookingDetails: bookingDetailsType
  isDetailsLoading: boolean
  bookingDetailsById: bookingListType | null
  orderDetails: {
    is_active: boolean
    booking_date: number
    order_details: {
      id: string
      status: string
      payments: []
      amount: number
      amount_due: number
      amount_paid: number
      created_at: number
      receipt: string
    }
  } | null
  isDetailsByIdLoading: boolean
  isOrderDertailsLoading: boolean
  pageInfo: {
    pageNo: number
    pageSize: number
    totalPages: number
    total: number
  }
  filters: FilterType
}

const initialFilterState = {
  dateSelector: "month",
  startDate: dayjs().startOf("month"),
  endDate: dayjs(),
  type: [DaypassType.DAYPASS, DaypassType.BULKDAYPASS],
  center: "All",
  isActive: null,
  paymentStatus: [PaymentStatus.PAID],
  bookingRef: "none",
  bookingCode: "",
}

export const bookingHistoryInitialState: BookingHistoryState = {
  bookingList: [],
  isLoading: false,
  bookingDetails: {
    profile_image: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    kyc_image: "",
    status: "",
    id: "",
    roles: [],
  },
  bookingDetailsById: null,
  orderDetails: null,
  isDetailsByIdLoading: false,
  isOrderDertailsLoading: false,
  isDetailsLoading: false,
  pageInfo: {
    pageNo: 1,
    pageSize: 10,
    totalPages: 0,
    total: 0,
  },
  filters: initialFilterState,
}

export type fetchBookingListPayloadType = {
  payload: {
    type?: string[]
    pageNo: number
    pageSize: number
    startDate?: string
    endDate?: string
    center?: string | null
    isActive?: boolean | null
    paymentStatus?: string[] | null
    bookingRef?: string | null
    userId?: string
    bookingCode?: string
  }
}

export type fetchBookingDetailsPayloadType = {
  payload: {
    userId: string
  }
}

export type fetchDaypassBookingByIdPayloadType = {
  payload: {
    bookingId: string
  }
}

export type modifyCenterPayloadType = {
  payload: {
    centerId: string
    bookingId: string
  }
}

export type modifyMobilePayloadType = {
  payload: {
    mobile: string
    bookingId: string
  }
}

export const bookingHistorySlice = createSlice({
  name: "bookingHistory",
  initialState: bookingHistoryInitialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchBookingList: (state, action: fetchBookingListPayloadType) => {
      state.bookingList = []
      state.isLoading = true
    },
    fetchBookingListSuccess: (
      state,
      {
        payload,
      }: { payload: { bookingList: []; totalPages: number; total: number } },
    ) => {
      state.bookingList = payload.bookingList
      state.pageInfo.totalPages = payload.totalPages
      state.pageInfo.total = payload.total
      state.isLoading = false
    },
    fetchBookingListFailed: (state) => {
      state.isLoading = false
    },

    updateOrderDetails: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: fetchDaypassBookingByIdPayloadType,
    ) => {
      state.isOrderDertailsLoading = true
    },
    updateOrderDetailsSuccess: (
      state,
      {
        payload,
      }: {
        payload: { orderDetails: any }
      },
    ) => {
      state.orderDetails = payload.orderDetails
      state.isOrderDertailsLoading = false
    },
    updateOrderDetailsFailed: (state) => {
      state.isOrderDertailsLoading = false
      state.orderDetails = null
    },

    updateOrderDetailsMarkAsActive: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: fetchDaypassBookingByIdPayloadType,
    ) => {
      state.isOrderDertailsLoading = true
    },
    updateOrderDetailsMarkAsActiveSuccess: (
      state,
      {
        payload,
      }: {
        payload: { orderDetails: any }
      },
    ) => {
      state.orderDetails = payload.orderDetails
      state.isOrderDertailsLoading = false
    },
    updateOrderDetailsMarkAsActiveFailed: (state) => {
      state.isOrderDertailsLoading = false
    },

    //

    fetchBookingDetailsById: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: fetchDaypassBookingByIdPayloadType,
    ) => {
      state.isDetailsByIdLoading = true
    },
    fetchBookingDetailsByIdSuccess: (
      state,
      {
        payload,
      }: {
        payload: { bookingDetailsById: bookingListType }
      },
    ) => {
      state.bookingDetailsById = payload.bookingDetailsById
      state.isDetailsByIdLoading = false
    },
    fetchBookingDetailsByIdFailed: (state) => {
      state.isDetailsByIdLoading = false
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchBookingDetails: (state, action: fetchBookingDetailsPayloadType) => {
      state.isDetailsLoading = true
    },
    fetchBookingDetailsSuccess: (
      state,
      { payload }: { payload: { bookingDetails: bookingDetailsType } },
    ) => {
      state.bookingDetails = payload.bookingDetails
      state.isDetailsLoading = false
    },
    fetchBookingDetailsFailed: (state) => {
      state.isDetailsLoading = false
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    modifyCenter: (state, _action: modifyCenterPayloadType) => {
      state.isDetailsByIdLoading = true
    },
    modifyCenterSuccess: (
      state,
      {
        payload,
      }: {
        payload: { bookingDetailsById: bookingListType }
      },
    ) => {
      state.bookingDetailsById = payload.bookingDetailsById
      state.isDetailsByIdLoading = false
    },
    modifyCenterFailed: (state) => {
      state.isDetailsByIdLoading = false
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    modifyMobile: (state, _action: modifyMobilePayloadType) => {
      state.isDetailsByIdLoading = true
    },
    modifyMobileSuccess: (
      state,
      {
        payload,
      }: {
        payload: { bookingDetailsById: bookingListType }
      },
    ) => {
      state.bookingDetailsById = payload.bookingDetailsById
      state.isDetailsByIdLoading = false
    },
    modifyMobileFailed: (state) => {
      state.isDetailsByIdLoading = false
    },

    setFilters: (state, { payload }) => {
      state.filters = payload
    },
    resetFilters: (state) => {
      state.filters = initialFilterState
      state.pageInfo.pageNo = 1
    },
    setPage: (state, { payload }) => {
      state.pageInfo.pageNo = payload
    },
    resetBookingDetails: (state) => {
      state.isDetailsLoading = false
      state.bookingDetails = {
        profile_image: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        kyc_image: "",
        status: "",
        id: "",
        roles: [],
      }
    },
  },
})

export const {
  fetchBookingList,
  fetchBookingListSuccess,
  fetchBookingListFailed,
  fetchBookingDetails,
  fetchBookingDetailsSuccess,
  fetchBookingDetailsFailed,
  fetchBookingDetailsById,
  fetchBookingDetailsByIdSuccess,
  fetchBookingDetailsByIdFailed,
  updateOrderDetails,
  updateOrderDetailsSuccess,
  updateOrderDetailsFailed,
  updateOrderDetailsMarkAsActive,
  updateOrderDetailsMarkAsActiveSuccess,
  updateOrderDetailsMarkAsActiveFailed,
  modifyCenter,
  modifyCenterFailed,
  modifyCenterSuccess,
  modifyMobile,
  modifyMobileFailed,
  modifyMobileSuccess,
  setFilters,
  setPage,
  resetFilters,
  resetBookingDetails,
} = bookingHistorySlice.actions

export const selectBookingHistory = (state: RootState) => state.bookingHistory

export default bookingHistorySlice.reducer
