import { PayloadAction } from "@reduxjs/toolkit"
import { call, put, takeLatest } from "redux-saga/effects"
import {
  fetchInvoicesFailure,
  fetchInvoicesRequest,
  fetchInvoicesSuccess,
  searchCustomersFailure,
  searchCustomersRequest,
  searchCustomersSuccess,
  submitReconciliationFailure,
  submitReconciliationRequest,
  submitReconciliationSuccess,
} from "../slices/reconcilationSlice"
import {
  fetchCustomerPendingInvoices,
  searchCustomers,
  submitReconciliation,
} from "../services/transactionsService"
import { ApiResponse } from "../models/api-response.model"
import { Customer, CustomerSearchResponse } from "../models/customer.model"
import {
  CustomerPendingInvoicesResponse,
  Invoice,
} from "../models/invoice.model"
import { ReconciliationDTO } from "../models/reconciliation.model"
import { showToast } from "../slices/toastSlice"

function* searchCustomersSaga(
  action: PayloadAction<{
    searchTerm: string
    transactionSource: string
  }>,
) {
  try {
    const customerSearchResponse: ApiResponse<CustomerSearchResponse> =
      yield call(searchCustomers, action.payload)
    yield put(
      searchCustomersSuccess(
        customerSearchResponse.data.contacts.map((item) => new Customer(item)),
      ),
    )
  } catch (error) {
    yield put(searchCustomersFailure())
  }
}

function* fetchInvoicesSaga(action: PayloadAction<string>) {
  try {
    const CustomerPendingInvoicesResponse: ApiResponse<CustomerPendingInvoicesResponse> =
      yield call(fetchCustomerPendingInvoices, action.payload)
    yield put(
      fetchInvoicesSuccess(
        CustomerPendingInvoicesResponse.data.invoices.map(
          (item) => new Invoice(item),
        ),
      ),
    )
  } catch (error: any) {
    yield put(
      fetchInvoicesFailure(
        error?.response?.data?.data?.message || error.message,
      ),
    )
  }
}

function* submitReconciliationSaga(action: PayloadAction<ReconciliationDTO>) {
  try {
    const response: ApiResponse<Record<string, never>> = yield call(
      submitReconciliation,
      action.payload,
    )
    yield put(submitReconciliationSuccess())
    yield put(
      showToast({
        toastType: response.success ? "success" : "info",
        msg: response.message,
      }),
    )
  } catch (error) {
    yield put(submitReconciliationFailure())
  }
}

export function* reconciliationSaga() {
  yield takeLatest(searchCustomersRequest.type, searchCustomersSaga)
  yield takeLatest(fetchInvoicesRequest.type, fetchInvoicesSaga)
  yield takeLatest(submitReconciliationRequest.type, submitReconciliationSaga)
}
